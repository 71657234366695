import "./Background.css";

export function Background() {
  return (
    <>
      <ul className="background">
        {/* <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li> */}
      </ul>
      <img class="hlwImg" src="./assets/images/hlw1.png" />
    </>
  );
}
